body {
    font-family: Arial, sans-serif;
    background-color: #e6f2ff;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    margin: 0;
  }
  
  .App {
    width: 100%;
    max-width: 400px;
    margin: 0 auto;
  }