.calculator-container {
  background-color: #e6f2ff;
  padding: 17px;
  border: 2px solid #000;
  border-radius: 10px;
  text-align: center;
  width: 100%;
  box-sizing: border-box;
  margin-top: 50px;
  margin-bottom: 30px;
  position: relative; /* Für den Info-Button */
}

.info-button {
  position: absolute;
  top: 5px;
  right: 10px;
  cursor: pointer;
}

.info-button span {
  text-decoration: underline; /* Unterstrichen */
  color: blue; /* Blaue Schriftfarbe */
  font-size: 10px;
}

.logo {
  width: 100px; /* Breite des Logos */
  margin-bottom: -40px; /* Abstand nach unten */
  margin-top: -30px;
}

h1 {
  font-weight: bold;
  color: #000;
  text-decoration: none;
}

.subtitle {
  font-style: italic; /* Kursiv */
  font-size: 12px; /* Etwas kleiner als die Überschrift */
  margin-top: -10px; /* Reduziert den Abstand zur Überschrift */
  margin-bottom: 10px; /* Abstand nach unten */
}

.divider {
  border: none;
  border-top: 1px solid #000; /* Schwarze Linie */
  margin: 10px 0; /* Abstand nach oben und unten */
}

.description {
  font-style: italic; /* Kursiv */
  margin-top: 10px; /* Abstand zur Linie */
  margin-bottom: 20px; /* Abstand nach unten */
}

.input-table {
  width: 100%;
  border-collapse: collapse; /* Entfernt Abstände zwischen Zellen */
  margin: 10px 0;
}

.input-group td {
  padding: 5px; /* Abstand innerhalb der Zellen */
  vertical-align: middle; /* Vertikale Ausrichtung */
}

.label-cell {
  text-align: left; /* Linksbündig */
}

.symbol-cell {
  text-align: left; /* Links- oder Rechtsbündig */
}

.input-label {
  font-weight: bold;
  color: #000;
  font-size: 16px; /* Schriftgröße */
}

.input-field {
  width: 50px; /* Breite des Input-Feldes */
  padding: 5px;
  border: 2px solid #000;
  border-radius: 5px;
  text-align: right;
  box-sizing: border-box; /* Box-Model anpassen */
  font-size: 16px; /* Schriftgröße */
  outline: none; /* Umrandung bei Fokus entfernen */
  margin-right: -10px; /* Hier kannst du den Abstand anpassen */
}

.volume-input {
  background-color: #e0f7ff; /* Hintergrundfarbe für Volume */
}

.percentage-input {
  background-color: #ffe0f7;
}

.price-input {
background-color: #f7ffe0; /* Hintergrundfarbe für Price */
}

.input-symbol {
font-weight: bold;
color: #000;
font-size: 14px; /* Schriftgröße */
margin-left: 1px; /* Hier kannst du den Abstand anpassen */
}

/* Zusätzliche Hover- und Fokus-Effekte für Input-Felder */
.input-field:hover {
border-color: #007bff; /* Rahmenfarbe bei Hover */
}

.input-field:focus {
border-color: #007bff;
}

button {
background-color: #ffcc99;
border: 1px solid #000;
border-radius: 5px;
padding: 15px 20px;
font-weight: bold;
cursor: pointer;
margin-top: 5px;
color: #000; /* Textfarbe schwarz */
}

button:hover {
background-color: #ffb366;
}

.save-button {
border: 1px solid #000;
background-color: #c6c6c6;
border-radius: 9px;
padding: 5px 10px;
font-weight: normal;
cursor: pointer;
margin-top: 10px;
color: #000000;
font-size: 8px;
}

.save-button:hover {
background-color: #bbb;
}

.reset-button {
border: 1px solid #000;
background-color: #dcdcdc;
border-radius: 5px;
padding: 3px 6px;
font-weight: normal;
font-style: italic;
cursor: pointer;
margin-left: 10px;
color: #000;
font-size: 7px;
opacity: 0.7;
}

.reset-button:hover {
background-color: #c0c0c0;
opacity: 1;
}

.disclaimer {
font-style: italic; /* Kursiv /
margin-top: 10px; / Abstand zur Linie /
margin-bottom: -10px; / Abstand nach unten */
font-size: 8px;
color: #8a8a8a;
}

.result {
margin-top: 20px;
}

.efficiency-value {
font-size: 24px;
font-weight: bold;
}

.slider-container {
margin-top: 10px;
position: relative;
}

.slider {
-webkit-appearance: none;
width: 100%;
height: 15px;
border-radius: 5px;
background: linear-gradient(to right, red, yellow, green);
outline: none;
border: 1px solid #000;
}

.slider::-webkit-slider-thumb {
-webkit-appearance: none;
appearance: none;
width: 25px;
height: 25px;
background: #fff;
border: 2px solid #000;
border-radius: 50%;
cursor: pointer;
}

.slider::-moz-range-thumb {
width: 25px;
height: 25px;
background: #fff;
border: 2px solid #000;
border-radius: 50%;
cursor: pointer;
}

.tip {
margin-top: 20px;
font-size: 12px;
font-style: italic; /* Kursiv */
color: #000;
}

.saved-results {
margin-top: 20px;
text-align: left;
}

.saved-results h5 {
font-size: 14px;
margin-bottom: 10px;
margin-top: 9px;
display: flex;
justify-content: space-between;
align-items: center;
}

.results-table {
width: 100%;
border-collapse: collapse;
}

.headTable {
font-size: 12px;
border-collapse: collapse;
}

.results-table th, .results-table td {
padding: 5px 10px;
border: 1px solid #989898;
}

.result-name {
text-align: left;
font-size: 10px;
}

.result-value {
text-align: right;
font-size: 9px;
}

.result-efficiency {
text-align: right;
font-size: 9px;
font-weight: bold;
}